.fc-toolbar-chunk {
    margin: 10px 10px 0px 10px;
}

.fc-col-header-cell-cushion {
    color: #000;
    text-decoration: none;
}
.fc-col-header-cell-cushion:hover {
    color:#000;
}
.fc-daygrid-day-number {
    color: #000;
    text-decoration: none;
}
.fc-daygrid-day-number:hover {
    color: #000;
}

.fc-event-title-container {
    text-align: center;
    cursor : pointer;
}

.fc-event-title {
    white-space: pre-line;
    font-size: 15px;
    font-weight: 900;
    word-break: keep-all;

    @media screen and (max-width: 768px) {
        font-size: 12px;
        font-weight: 900;
    }
}

.fc-event-time {
    display: none;
}